import "./teachers.scss";

import * as React from "react";
import { PropsWithChildren } from "react";

interface Teacher {
  name: string;
  features: string[];
}

interface PropsTeachers extends PropsWithChildren<any> {
  teachers: Teacher[];
}

const Teachers: React.FC<PropsTeachers> = (props: PropsTeachers) => {
  const teachers_render: JSX.Element[] = [];

  props.teachers.forEach((teacher, index) => {
    teachers_render.push(
      <div className={"o-teachers__card"} key={index}>
        <p className={"a-teachers__name h3"}>{teacher.name}</p>
        <ul>
          {teacher.features.map((feature, index) => (
            <li className={"a-teachers__feature"} key={index}>{feature}</li>
          ))}
        </ul>
      </div>
    );
  });

  return <section className={"o-teachers"}>{teachers_render}</section>;
};

export default Teachers;
