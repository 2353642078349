import { graphql } from "gatsby";
import React from "react";

import Layout from "../components/common/layout/layout";
// import { Contact } from "../components/Forms/Contact/Contact";
// import withForm from "../components/Forms/withForm";
import Hero from "../components/Hero/Hero";
import Map from "../components/Map/Map";
import SEO from "../components/SEO/SEO";
import Teachers from "../components/Teachers/Teachers";
import PcisBanners from "../PCIS/components/PCIsBanner/PcisBanners";

const PageProfesorado = ({ data }) => {
  // const ContactWithForm = withForm(Contact, "Profesorado");
  return (
    <>
      <SEO
        title={"Profesorado PCI IES Ramón y Cajal"}
        canonical={"profesorado"}
        description={""}
        robots={"index, follow"}
      />
      <Layout>
        <Hero title={"Profesorado PCI IES Ramón y Cajal"} />
        <main>
          {/*<section className={"container"}>*/}
          {/*  <div style={{ maxWidth: "800px" }}>*/}
          {/*    <p className={"h2"}>*/}
          {/*      Et omnia in potestate nostra esse natura liber, libera, libere valeant; sed illis non est in nostra*/}
          {/*      potestate sunt infirmi, servilis, licet, lex pertinet.*/}
          {/*    </p>*/}
          {/*    <p>*/}
          {/*      Et omnia in potestate nostra esse natura liber, libera, libere valeant; sed illis non est in nostra*/}
          {/*      potestate sunt infirmi, servilis, licet, lex pertinet.*/}
          {/*    </p>*/}
          {/*  </div>*/}
          {/*</section>*/}
          <section className={"container"}>
            <Teachers teachers={data.allTeachersJson.nodes} />
          </section>

          <PcisBanners />
          <Map>
            <h2> ¿Necesitas ponerte en contacto con el departamento PCI IES Ramón y Cajal?</h2>
            <p>
              Si tienes más dudas, envía un correo electrónico a la siguiente dirección{" "}
              <a href={"mailto:pci.ryc.zgz@gmail.com"} target={"_blank"} rel={"noopener noreferrer"}>
                {" "}
                pci.ryc.zgz@gmail.com{" "}
              </a>{" "}
              o rellena el siguiente formulario de información
            </p>
            {/*<ContactWithForm />*/}
          </Map>
        </main>
      </Layout>
    </>
  );
};

export default PageProfesorado;

export const query = graphql`
  query Teachers {
    allTeachersJson {
      nodes {
        name
        features
      }
    }
  }
`;
